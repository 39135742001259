module.exports = {
  hierarchy : {
    small : [
      'dropdown-small'
    ],
    quickViews : [
      'dropdown-quickViews'
    ],
    leftPropertySelector : [
      'dropdown-leftPropertySelector'
    ],
    form : [
      'dropdown-form'
    ],
    inline : [
      'dropdown-inline'
    ],
    zones : [
      'dropdown-zones'
    ],
    outlineStyles : [
      'dropdown-outlineStyles'
    ],
    alerts : [
      'dropdown-zones',
      'dropdown-alerts'
    ],
    ruleField : [
      'dropdown-quickViews',
      'dropdown-ruleField'
    ],
    fullWidth : [
      'dropdown-quickViews',
      'dropdown-fullWidth'
    ],
    dmsRuleField : [
      'dropdown-quickViews',
      'dropdown-ruleField',
      'dropdown-dmsRuleField'
    ]
  },
  props : {
    default : {
      selectIcon : 'fa fa-chevron-down'
    },
    small : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    quickViews : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    leftPropertySelector : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    form : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    inline : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    zones : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    outlineStyles : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    alerts : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    ruleField : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    fullWidth : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    dmsRuleField : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    }
  }
}
