// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".splitPane_2s9CJ {\n  --thickness: 1px;\n}\n.splitPane_2s9CJ .Pane + .Resizer {\n  opacity: 1;\n  --offset1: 0px;\n  --offset2: 1px;\n  --bordertop: 5px;\n  --borderleft: 5px;\n  --borderright: 5px;\n  --borderbottom: 5px;\n  background-clip: padding-box;\n  --bordertopcolor: rgba(255, 255, 255, 0);\n  --bordertopstyle: solid;\n  background-color: transparent;\n  --borderleftcolor: rgba(255, 255, 255, 0);\n  --borderleftstyle: solid;\n  --borderrightcolor: rgba(255, 255, 255, 0);\n  --borderrightstyle: solid;\n  --borderbottomcolor: rgba(255, 255, 255, 0);\n  --borderbottomstyle: solid;\n}\n.splitPane_2s9CJ .Pane + .Resizer.vertical {\n  border-left-color: var(--borderleftcolor);\n  border-left-width: var(--borderleft);\n  border-left-style: var(--borderleftstyle);\n  border-right-color: var(--borderrightcolor);\n  border-right-width: var(--borderright);\n  border-right-style: var(--borderrightstyle);\n}\n.splitPane_2s9CJ .Pane + .Resizer.horizontal {\n  border-top-color: var(--bordertopcolor);\n  border-top-width: var(--bordertop);\n  border-top-style: var(--bordertopstyle);\n  border-bottom-color: var(--borderbottomcolor);\n  border-bottom-width: var(--borderbottom);\n  border-bottom-style: var(--borderbottomstyle);\n}\n.splitPane_2s9CJ .Pane + .Resizer:hover {\n  --bordertopcolor: rgba(0, 0, 0, 0.5);\n  --borderleftcolor: rgba(0, 0, 0, 0.5);\n  --borderrightcolor: rgba(0, 0, 0, 0.5);\n  --borderbottomcolor: rgba(0, 0, 0, 0.5);\n}\n.splitPane_2s9CJ .Pane1 {\n  background-color: var(--color-white);\n}\n.splitPane_2s9CJ .Pane2 {\n  background-color: var(--color-background3);\n}\n", ""]);
// Exports
exports.locals = {
	"splitPane": "splitPane_2s9CJ"
};
module.exports = exports;
