// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".body_3zO4O {\n  margin-top: 24px;\n  padding: 0 25px;\n  width: 100%;\n  max-width: 456px;\n  margin: 24px auto 0 auto;\n}\n.scanText_L68lZ {\n  color: #383F50;\n  font-size: 16px;\n  font-weight: 600;\n  padding: 0;\n}\n.noPadding_2mmb8 {\n  padding: 0;\n  margin: 0;\n}\n.errorText_1YhRa {\n  color: #CB0A13;\n  font-size: 14px;\n  font-weight: 600;\n}\n", ""]);
// Exports
exports.locals = {
	"body": "body_3zO4O",
	"scanText": "scanText_L68lZ",
	"noPadding": "noPadding_2mmb8",
	"errorText": "errorText_1YhRa"
};
module.exports = exports;
