import Path from '@leverege/path';
import Constants from '../actions/Constants';

let initialState = {
  header : {
    variantClass : ''
  },
  tracker : {
    value : '',
    error : false,
    showError : false
  },
  asset : {
    value : '',
    error : false,
    showError : false
  },
  scanHeader : {
    variant : '',
    text : null,
    cancel : true
  },
  mode : Constants.MODE_PAIR,
  showModal : false
}

if ( localStorage.getItem( 'UI_STATE' ) ) {
  try {
    const savedState = JSON.parse( localStorage.getItem( 'UI_STATE' ) )
    if ( savedState.showModal === undefined ) {
      savedState.showModal = false
    }
    initialState = savedState
  } catch ( ex ) {
    console.log( ex )
  }
}

function ui( state = initialState, action ) {
  let nState
  if ( action.type === Constants.UI_SET_KEY ) {
    nState = { ...state }
    Path.set( action.key, nState, action.value )
  } else if ( action.type === Constants.UI_MULTI_SET_KEY ) {
    nState = { ...state }
    Object.keys( action.update ).forEach( ( path ) => {
      Path.set( path, nState, action.update[path] )
    } )
  } else {
    nState = state
  }
  localStorage.setItem( 'UI_STATE', JSON.stringify( nState ) )
  return nState
}

module.exports = ui;
