import { useAuth0 } from '@auth0/auth0-react'
import Constants from './Constants'
import Util from '../util'

const { getSiteIdFromUrl } = Util.LocationUtil 

function verify() {
  return ( dispatch, getState, { pinpointApi } ) => {
    dispatch( { type : Constants.VERIFY_PENDING } )
    return pinpointApi.child( 'v1/verify' ).get( { siteId : getSiteIdFromUrl() } )
      .then( ( p ) => {
        dispatch( { type : Constants.LOGIN_SUCCESS, profile : p, idToken : p.idToken } )
      }, ( err ) => {
        console.log( err )
        pinpointApi.options.auth = null
        return dispatch( { type : Constants.LOGIN_FAILURE, error : err } )
      } )
  }
}
function login( username, password ) {
  // reset everything
  localStorage.removeItem( 'id_token' )
  localStorage.removeItem( 'profile' )

  return ( dispatch, getState, { pinpointApi } ) => {
    dispatch( { type : Constants.LOGIN_PENDING } )
    return pinpointApi.child( 'v1/login' ).post( { username, password, siteId : getSiteIdFromUrl() } )
      .then( ( p ) => {
        pinpointApi.options.auth = { getToken : () => `Bearer ${p.idToken}` }
        dispatch( { type : Constants.LOGIN_SUCCESS, profile : p, idToken : p.idToken } ) 
      } )
      .catch( ( err ) => {
        console.log( err )
        return dispatch( { type : Constants.LOGIN_FAILURE, error : err } )
      } )
  }
}

function isVerifying( state ) {
  return ( state && state.status ) === Constants.VERIFY_PENDING
}

function isLoggingIn( state ) {
  return ( state && state.status ) === Constants.LOGIN_PENDING
}

function isLoggedIn( state ) {
  return ( state && state.status ) === Constants.LOGIN_SUCCESS
}

function logout() {
  return async ( dispatch, getState, { api } ) => {
    return dispatch( { type : Constants.LOGOUT } )
  }
}

function redirectToAuth0( ) { 
  const { loginWithRedirect, isAuthenticated } = useAuth0()

  if ( !isAuthenticated ) { 
    loginWithRedirect()
  }
}

function verifyAuth0( token, isAuthenticated, user, dispatch ) {
  if ( isAuthenticated ) {
    dispatch( { type : Constants.LOGIN_SUCCESS, profile : user, idToken : token } )
  }
}

function logoutAuth0( logout, dispatch ) {
  dispatch( { type : Constants.LOGOUT } )

  logout( {
    returnTo : window.location.origin
  } )
  dispatch( { type : Constants.LOGOUT } )

}

function hasPermission( permissions, siteId ) {
  // eslint-disable-next-line camelcase
  return !!permissions?.find( x => x?.site_id === siteId )
}

function getAuth0Metadata( profile ) {
  return profile[`${Constants.AUTH0_NAMESPACE}user_metadata`]
}

module.exports = {
  verify, login, logout, isVerifying, isLoggedIn, isLoggingIn, redirectToAuth0, verifyAuth0, logoutAuth0, hasPermission, getAuth0Metadata
}
