// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".portal_3IyIB {\n  background-color: var(--color-shadow1);\n}\n.portal_3IyIB.portal-mobileMenu_2MKTg {\n  background-color: #00305e;\n}\n.portal_3IyIB.portal-dialog_RM41P {\n  background-color: rgba(203, 203, 203, 0.32);\n}\n.portal_3IyIB.portal-confirmContactDelete_1pzHJ {\n  background: transparent;\n}\n.portal_3IyIB.portal-exceptionDetail_2Oy9n {\n  background-color: var(--color-shadow1);\n}\n", ""]);
// Exports
exports.locals = {
	"portal": "portal_3IyIB",
	"portal-mobileMenu": "portal-mobileMenu_2MKTg",
	"portal-dialog": "portal-dialog_RM41P",
	"portal-confirmContactDelete": "portal-confirmContactDelete_1pzHJ",
	"portal-exceptionDetail": "portal-exceptionDetail_2Oy9n"
};
module.exports = exports;
