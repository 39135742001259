import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import { BrowserRouter } from 'react-router-dom'

import Thunk from 'redux-thunk'
import Api from '@leverege/api'

import { Theme } from '@leverege/ui-elements'
import ActiveTheme from '@leverege/ui-active-theme'
import Auth0ProviderWithHistory from './components/shared/auth/Auth0ProviderWithHistory'

import AppTheme from './theme'

import './css/global-style.less'
import App from './App'
import { Config } from './config'
import reducers from './reducers'
import { LoginActions } from './actions'
import { gtmInit } from './util/GoogleTagManager'

function start( opts = { } ) {

  // Google Tag Manager
  gtmInit();
    
  const pinpointApi = Api.init( { storeToken : true, host : Config.HOST } )
  const history = createBrowserHistory()
  const store = createStore( 
    reducers( { history } ), 
    applyMiddleware( 
      Thunk.withExtraArgument( { pinpointApi } ),
      routerMiddleware( history )
    )
  )
  let profile
  try {
    profile = JSON.parse( localStorage.getItem( 'profile' ) ) 
  } catch ( e ) {}
  if ( profile && profile.type === 'leverege' ) store.dispatch( LoginActions.verify() )

  let theme 
  if ( process.env.NODE_ENV === 'development' ) {
    theme = new ActiveTheme(
      { appearanceProjectId : '1VMVM6L4eupAsUJHt9ELvz',
        altTheme : AppTheme,
        config : {
          apiKey : 'AIzaSyClFQfpPnkg_VH9ER76CeH9uCwM978DwDg',
          authDomain : 'cox2m-ui-builder.firebaseapp.com',
          databaseURL : 'https://cox2m-ui-builder.firebaseio.com',
          projectId : 'cox2m-ui-builder',
          storageBucket : 'cox2m-ui-builder.appspot.com',
          messagingSenderId : '820406166945',
          appId : '1:820406166945:web:f73916fe0f4a4d5908d7e0',
        }
      }
    )
  } else {
    theme = AppTheme
  }

  window.store = store

  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <BrowserRouter>
          <Auth0ProviderWithHistory>
            <Theme theme={theme}>
              <App />
            </Theme>
          </Auth0ProviderWithHistory>
        </BrowserRouter>
      </ConnectedRouter>
    </Provider>,
    document.getElementById( 'root' ) )
}

window.Scanner = { start }
