// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".vTable_QeMeH {\n  font: var(--size-fontSmall) var(--fontFamily-primary);\n  color: #29323d;\n  border-top: none;\n  border-bottom: none;\n}\n.vTable_QeMeH .header_1f0j4 {\n  border-top: 1px solid #e8ecf0;\n  font-weight: 600;\n  line-height: 16px;\n  border-bottom: 1px solid #e8ecf0;\n  background-color: var(--color-background9);\n}\n.vTable_QeMeH .row_khGzU {\n  border-top: 1px solid #e8ecf0;\n  border-left: none;\n  line-height: 36px;\n  border-right: none;\n}\n.vTable_QeMeH .row_khGzU.odd_2uEjO {\n  background-color: var(--color-background2);\n}\n.vTable_QeMeH .headerCell_2T5Um {\n  padding-left: var(--size-padMedium);\n  padding-right: var(--size-padMedium);\n}\n.vTable_QeMeH .bodyCell_BCpo2 {\n  padding-left: var(--size-padMedium);\n  padding-right: var(--size-padMedium);\n}\n.vTable_QeMeH .headerCell_2T5Um:first-child {\n  border-left: none;\n}\n.vTable_QeMeH .bodyCell_BCpo2:first-child {\n  border-left: none;\n}\n", ""]);
// Exports
exports.locals = {
	"vTable": "vTable_QeMeH",
	"header": "header_1f0j4",
	"row": "row_khGzU",
	"odd": "odd_2uEjO",
	"headerCell": "headerCell_2T5Um",
	"bodyCell": "bodyCell_BCpo2"
};
module.exports = exports;
