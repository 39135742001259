module.exports = {
  hierarchy : {
    small : [
      'toggleButton-small'
    ],
    link : [
      'toggleButton-link'
    ],
    filter : [
      'toggleButton-filter'
    ],
    ascending : [
      'toggleButton-filter',
      'toggleButton-ascending'
    ],
    descending : [
      'toggleButton-filter',
      'toggleButton-ascending',
      'toggleButton-descending'
    ],
    circle : [
      'toggleButton-circle'
    ],
    user : [
      'toggleButton-circle',
      'toggleButton-user'
    ],
    mainMenuLink : [
      'toggleButton-mainMenuLink'
    ],
    mapControl : [
      'toggleButton-circle',
      'toggleButton-mapControl'
    ],
    globeControl : [
      'toggleButton-globeControl'
    ],
    locationControl : [
      'toggleButton-circle',
      'toggleButton-mapControl',
      'toggleButton-locationControl'
    ],
    found : [
      'toggleButton-found'
    ],
    subMenuLink : [
      'toggleButton-subMenuLink'
    ],
    vehicleDrillDownTab : [
      'toggleButton-vehicleDrillDownTab'
    ],
    overdueAck : [
      'toggleButton-overdueAck'
    ],
    timeSpanToggle : [
      'toggleButton-timeSpanToggle'
    ],
    tag : [
      'toggleButton-tag'
    ],
    exception : [
      'toggleButton-exception'
    ]
  },
  props : {
    default : {},
    small : {},
    link : {},
    filter : {},
    ascending : {
      iconOff : 'fa fa-sort-alpha-asc',
      iconOn : 'fa fa-sort-alpha-asc'
    },
    descending : {
      iconOff : 'fa fa-sort-alpha-desc',
      iconOn : 'fa fa-sort-alpha-desc'
    },
    circle : {},
    user : {
      iconOff : 'fa fa-user',
      iconOn : 'fa fa-user'
    },
    mainMenuLink : {},
    mapControl : {},
    globeControl : {
      iconOff : 'fa fa-globe',
      iconOn : 'fa fa-map'
    },
    locationControl : {
      iconOff : 'fa fa-crosshairs',
      iconOn : 'fa fa-crosshairs'
    },
    found : {
      iconOn : 'fa fa-check',
      off : 'Mark as Found',
      on : 'Found'
    },
    subMenuLink : {},
    vehicleDrillDownTab : {},
    overdueAck : {
      iconOff : 'icon ftr-user-check',
      iconOn : 'icon ftr-check',
      off : 'Acknowledge Overdue',
      on : 'Acknowledged'
    },
    timeSpanToggle : {},
    tag : {},
    exception : {
      iconOff : 'fa fa-chevron-down',
      iconOn : 'fa fa-chevron-up'
    }
  }
}
