module.exports = {
  hierarchy : {
    rowS : [
      'flex-rowS'
    ],
    rowM : [
      'flex-rowM'
    ],
    rowL : [
      'flex-rowL'
    ],
    col : [
      'flex-col'
    ],
    colS : [
      'flex-colS'
    ],
    colM : [
      'flex-colM'
    ],
    colL : [
      'flex-colL'
    ],
    footer : [
      'flex-footer'
    ],
    settingsColM : [
      'flex-colL',
      'flex-settingsColM'
    ],
    settingsRowL : [
      'flex-rowL',
      'flex-settingsRowL'
    ],
    settingsColL : [
      'flex-colL',
      'flex-settingsColL'
    ],
    settingsMiddleColL : [
      'flex-colL',
      'flex-settingsMiddleColL'
    ],
    alertMessage : [
      'flex-rowS',
      'flex-alertMessage'
    ],
    dialogButtonLayout : [
      'flex-dialogButtonLayout'
    ],
    passwordFields : [
      'flex-passwordFields'
    ],
    passwordRow : [
      'flex-passwordRow'
    ],
    checkbox : [
      'flex-checkbox'
    ],
    vehicleDetails : [
      'flex-vehicleDetails'
    ],
    vehicleDetailRow : [
      'flex-vehicleDetailRow'
    ],
    detailPane : [
      'flex-col',
      'flex-detailPane'
    ],
    vehicleTitle : [
      'flex-vehicleTitle'
    ],
    acknowledge : [
      'flex-acknowledge'
    ],
    ackText : [
      'flex-ackText'
    ],
    logoutRow : [
      'flex-logoutRow'
    ],
    status : [
      'flex-status'
    ],
    statusBar : [
      'flex-statusBar'
    ],
    mobileHeader : [
      'flex-colM',
      'flex-mobileHeader'
    ],
    rowSPad : [
      'flex-rowS',
      'flex-rowSPad'
    ],
    colMPad : [
      'flex-colM',
      'flex-colMPad'
    ],
    detailCard : [
      'flex-col',
      'flex-detailCard'
    ],
    pillBox : [
      'flex-pillBox'
    ],
    ackBar : [
      'flex-ackBar'
    ],
    ackInfo : [
      'flex-ackInfo'
    ],
    statusContainer : [
      'flex-statusContainer'
    ],
    currentStatusRow : [
      'flex-currentStatusRow'
    ],
    headerRow : [
      'flex-headerRow'
    ],
    zoomFlex : [
      'flex-zoomFlex'
    ],
    tableHeader : [
      'flex-tableHeader'
    ],
    dateRangeTimePicker : [
      'flex-dateRangeTimePicker'
    ],
    lotEditingForm : [
      'flex-lotEditingForm'
    ],
    timelineCardTitle : [
      'flex-timelineCardTitle'
    ],
    detailedHistoryRow : [
      'flex-detailedHistoryRow'
    ],
    alertPillBox : [
      'flex-pillBox',
      'flex-alertPillBox'
    ],
    zoneText : [
      'flex-zoneText'
    ],
    noSpace : [
      'flex-noSpace'
    ],
    ruleCardFooter : [
      'flex-ruleCardFooter'
    ],
    header : [
      'flex-header'
    ],
    historyFlex : [
      'flex-historyFlex'
    ],
    rowMPad : [
      'flex-rowM',
      'flex-rowMPad'
    ],
    ruleFormContainer : [
      'flex-ruleFormContainer'
    ],
    historyHeader : [
      'flex-header',
      'flex-historyHeader'
    ],
    wideFormField : [
      'flex-wideFormField'
    ],
    rowLPad : [
      'flex-rowM',
      'flex-rowMPad',
      'flex-rowLPad'
    ],
    infoBox : [
      'flex-rowS',
      'flex-infoBox'
    ]
  },
  props : {
    default : {},
    rowS : {},
    rowM : {},
    rowL : {},
    col : {},
    colS : {},
    colM : {},
    colL : {},
    footer : {},
    settingsColM : {},
    settingsRowL : {},
    settingsColL : {},
    settingsMiddleColL : {},
    alertMessage : {},
    dialogButtonLayout : {},
    passwordFields : {},
    passwordRow : {},
    checkbox : {},
    vehicleDetails : {},
    vehicleDetailRow : {},
    detailPane : {},
    vehicleTitle : {},
    acknowledge : {},
    ackText : {},
    logoutRow : {},
    status : {},
    statusBar : {},
    mobileHeader : {},
    rowSPad : {},
    colMPad : {},
    detailCard : {},
    pillBox : {},
    ackBar : {},
    ackInfo : {},
    statusContainer : {},
    currentStatusRow : {},
    headerRow : {},
    zoomFlex : {},
    tableHeader : {},
    dateRangeTimePicker : {},
    lotEditingForm : {},
    timelineCardTitle : {},
    detailedHistoryRow : {},
    alertPillBox : {},
    zoneText : {},
    noSpace : {},
    ruleCardFooter : {},
    header : {},
    historyFlex : {},
    rowMPad : {},
    ruleFormContainer : {},
    historyHeader : {},
    wideFormField : {},
    rowLPad : {},
    infoBox : {}
  }
}
