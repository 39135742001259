// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".center_Cvbh4 {\n  flex-grow: 1;\n}\n.actionArrow_2jFJG {\n  font-size: 16px;\n  color: #435071;\n  width: 90px;\n}\n.container_3Mavb {\n  width: 100%;\n  max-width: 600px;\n  padding-bottom: 24px;\n}\n.circleEmpty_2g2ym {\n  color: #D1D3E1;\n  margin: 0 5px;\n}\n.circleFull_3qg4k {\n  color: #435071;\n  margin: 0 5px;\n}\n.footer_2MYEn {\n  display: flex;\n  justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"center": "center_Cvbh4",
	"actionArrow": "actionArrow_2jFJG",
	"container": "container_3Mavb",
	"circleEmpty": "circleEmpty_2g2ym",
	"circleFull": "circleFull_3qg4k",
	"footer": "footer_2MYEn"
};
module.exports = exports;
