// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".toast_Hkj2S {\n  color: var(--color-white);\n  background-color: var(--color-brand1);\n}\n.toast_Hkj2S > .Toastify__progress-bar {\n  background: none;\n}\n.toast_Hkj2S.toast-success_1blra {\n  background-color: var(--color-alertInfoA);\n}\n.toast_Hkj2S.toast-error_l0tpI {\n  color: var(--color-white);\n  font-family: var(--fontFamily-OpenSans);\n  background-color: var(--color-alertDangerA);\n}\n.toast_Hkj2S.toast-warn_vJ7RU {\n  background-color: var(--color-alertWarnA);\n}\n.toast_Hkj2S.toast-info_j5UNL {\n  background-color: var(--color-alertSuccessA);\n}\n", ""]);
// Exports
exports.locals = {
	"toast": "toast_Hkj2S",
	"toast-success": "toast-success_1blra",
	"toast-error": "toast-error_l0tpI",
	"toast-warn": "toast-warn_vJ7RU",
	"toast-info": "toast-info_j5UNL"
};
module.exports = exports;
