import Constants from './Constants'

exports.set = ( key, value ) => {
  return { type : Constants.UI_SET_KEY, key, value }
}

/**
 * Sends an action to set many keys at once
 * @param {object} keyValue an object whose keys represent
 * a path to the field and the value is the value. For example:
 * {
 *   'a/b' : true,
 *   'c' : 1
 * }
 * In the above, state.ui.a.b will be set to true and state.ui.c will
 * be set to 1.
 **/
exports.multiSet = ( keyValue ) => {
  return { type : Constants.UI_MULTI_SET_KEY, update : keyValue }
}
