module.exports = {
  hierarchy : {
    small : [
      'text-small'
    ],
    dialogMessage : [
      'text-dialogMessage'
    ],
    tableHeader : [
      'text-tableHeader'
    ],
    filterHeader : [
      'text-filterHeader'
    ],
    queryResult : [
      'text-queryResult'
    ],
    filterCount : [
      'text-filterCount'
    ],
    detailBlockHeader : [
      'text-detailBlockHeader'
    ],
    detailBlockRowTitle : [
      'text-detailBlockRowTitle'
    ],
    detailBlockRowValue : [
      'text-detailBlockRowValue'
    ],
    settingsDescription : [
      'text-settingsDescription'
    ],
    smallAlert : [
      'text-smallAlert'
    ],
    alertPreferences : [
      'text-alertPreferences'
    ],
    tcMakeModel : [
      'text-tcMakeModel'
    ],
    tcTag : [
      'text-tcTag'
    ],
    tcVin : [
      'text-tcVin'
    ],
    tcTagAlert : [
      'text-tcTag',
      'text-tcTagAlert'
    ],
    settings : [
      'text-settings'
    ],
    light : [
      'text-filterHeader',
      'text-light'
    ],
    paneInfo : [
      'text-paneInfo'
    ],
    error : [
      'text-error'
    ],
    detailBlockHeaderSmall : [
      'text-detailBlockHeader',
      'text-detailBlockHeaderSmall'
    ],
    contactsUpdateDeletePopup : [
      'text-contactsUpdateDeletePopup'
    ],
    title : [
      'text-title'
    ],
    filterCountDisabled : [
      'text-filterCount',
      'text-filterCountDisabled'
    ],
    contactFormTitle : [
      'text-contactFormTitle'
    ],
    dialogTitle : [
      'text-contactFormTitle',
      'text-dialogTitle'
    ],
    paginationPages : [
      'text-paginationPages'
    ],
    logoutMenuTitle : [
      'text-logoutMenuTitle'
    ],
    logoutMenuContent : [
      'text-logoutMenuContent'
    ],
    secondaryHeader : [
      'text-secondaryHeader'
    ],
    detailTitle : [
      'text-detailTitle'
    ],
    smallDescription : [
      'text-smallDescription'
    ],
    lastReportText : [
      'text-lastReportText'
    ],
    explanation : [
      'text-explanation'
    ],
    logoutText : [
      'text-logoutText'
    ],
    inputCell : [
      'text-inputCell'
    ],
    secondaryHeaderPadded : [
      'text-secondaryHeader',
      'text-secondaryHeaderPadded'
    ],
    vinText : [
      'text-vinText'
    ],
    eventAckInfo : [
      'text-eventAckInfo'
    ],
    ackHeader : [
      'text-ackHeader'
    ],
    statusHeader : [
      'text-statusHeader'
    ],
    hint : [
      'text-inputCell',
      'text-hint'
    ],
    graphSectionLabel : [
      'text-graphSectionLabel'
    ],
    graphHeader : [
      'text-graphHeader'
    ],
    graphLabel : [
      'text-graphLabel'
    ],
    lotName : [
      'text-lotName'
    ],
    statusTitle : [
      'text-statusTitle'
    ],
    historyLabel : [
      'text-historyLabel'
    ],
    ackLoading : [
      'text-ackLoading'
    ],
    overdueText : [
      'text-overdueText'
    ],
    overdueTextLight : [
      'text-overdueText',
      'text-overdueTextLight'
    ],
    currentStatus : [
      'text-currentStatus'
    ],
    statusContact : [
      'text-statusContact'
    ],
    notes : [
      'text-notes'
    ],
    statusBarSubtitles : [
      'text-statusBarSubtitles'
    ],
    logoText : [
      'text-logoText'
    ],
    historyTitle : [
      'text-historyTitle'
    ],
    detailsTitle : [
      'text-detailsTitle'
    ],
    drillBlockRowValue : [
      'text-drillBlockRowValue'
    ],
    drillBlockRowTitle : [
      'text-drillBlockRowTitle'
    ],
    detailedHistory : [
      'text-detailedHistory'
    ],
    detailedHistoryTitle : [
      'text-detailedHistoryTitle'
    ],
    detailedHistoryTime : [
      'text-detailedHistoryTime'
    ],
    detailedHistoryDate : [
      'text-detailedHistoryDate'
    ],
    detailedHistoryIndex : [
      'text-detailedHistoryIndex'
    ],
    detailedHistoryHeader : [
      'text-detailedHistoryHeader'
    ],
    detailedHistoryLength : [
      'text-detailedHistoryTime',
      'text-detailedHistoryLength'
    ],
    alertsTitle : [
      'text-alertsTitle'
    ],
    drillDownVehicleTitle : [
      'text-drillDownVehicleTitle'
    ],
    ackByText : [
      'text-ackByText'
    ],
    legendTitle : [
      'text-legendTitle'
    ],
    countLabel : [
      'text-countLabel'
    ],
    countValue : [
      'text-countValue'
    ],
    secondaryLegendTitle : [
      'text-secondaryLegendTitle'
    ],
    poweredBy : [
      'text-statusContact',
      'text-poweredBy'
    ],
    alertLabel : [
      'text-alertLabel'
    ],
    scanText : [
      'text-scanText'
    ],
    modeHeader : [
      'text-modeHeader'
    ],
    scanHeader : [
      'text-scanHeader'
    ],
    errorText : [
      'text-error',
      'text-errorText'
    ],
    modalHeader : [
      'text-scanHeader',
      'text-modalHeader'
    ],
    ruleCardTitle : [
      'text-scanHeader',
      'text-modalHeader',
      'text-ruleCardTitle'
    ],
    alert : [
      'text-alert'
    ],
    alertSmall : [
      'text-small',
      'text-alertSmall'
    ],
    historyFiller : [
      'text-historyFiller'
    ],
    exceptionPill : [
      'text-exceptionPill'
    ],
    exceptionDataBlockTitle : [
      'text-exceptionDataBlockTitle'
    ],
    exceptionDataBlockTitleAlert : [
      'text-exceptionDataBlockTitle',
      'text-exceptionDataBlockTitleAlert'
    ],
    failedExceptionText : [
      'text-historyFiller',
      'text-failedExceptionText'
    ],
    infoBox : [
      'text-infoBox'
    ],
    indexBox : [
      'text-indexBox'
    ],
    errorFilled : [
      'text-errorFilled'
    ],
    divider : [
      'text-divider'
    ]
  },
  props : {
    default : {},
    small : {},
    dialogMessage : {},
    tableHeader : {},
    filterHeader : {},
    queryResult : {},
    filterCount : {},
    detailBlockHeader : {},
    detailBlockRowTitle : {},
    detailBlockRowValue : {},
    settingsDescription : {},
    smallAlert : {},
    alertPreferences : {},
    tcMakeModel : {},
    tcTag : {},
    tcVin : {},
    tcTagAlert : {},
    settings : {},
    light : {},
    paneInfo : {},
    error : {},
    detailBlockHeaderSmall : {},
    contactsUpdateDeletePopup : {},
    title : {},
    filterCountDisabled : {},
    contactFormTitle : {},
    dialogTitle : {},
    paginationPages : {},
    logoutMenuTitle : {},
    logoutMenuContent : {},
    secondaryHeader : {},
    detailTitle : {},
    smallDescription : {},
    lastReportText : {},
    explanation : {},
    logoutText : {},
    inputCell : {},
    secondaryHeaderPadded : {},
    vinText : {},
    eventAckInfo : {},
    ackHeader : {},
    statusHeader : {},
    hint : {},
    graphSectionLabel : {},
    graphHeader : {},
    graphLabel : {},
    lotName : {},
    statusTitle : {},
    historyLabel : {},
    ackLoading : {},
    overdueText : {},
    overdueTextLight : {},
    currentStatus : {},
    statusContact : {},
    notes : {},
    statusBarSubtitles : {},
    logoText : {},
    historyTitle : {},
    detailsTitle : {},
    drillBlockRowValue : {},
    drillBlockRowTitle : {},
    detailedHistory : {},
    detailedHistoryTitle : {},
    detailedHistoryTime : {},
    detailedHistoryDate : {},
    detailedHistoryIndex : {},
    detailedHistoryHeader : {},
    detailedHistoryLength : {},
    alertsTitle : {},
    drillDownVehicleTitle : {},
    ackByText : {},
    legendTitle : {},
    countLabel : {},
    countValue : {},
    secondaryLegendTitle : {},
    poweredBy : {},
    alertLabel : {},
    scanText : {},
    modeHeader : {},
    scanHeader : {},
    errorText : {},
    modalHeader : {},
    ruleCardTitle : {},
    alert : {},
    alertSmall : {},
    historyFiller : {},
    exceptionPill : {},
    exceptionDataBlockTitle : {},
    exceptionDataBlockTitleAlert : {},
    failedExceptionText : {},
    infoBox : {},
    indexBox : {},
    errorFilled : {},
    divider : {}
  }
}
