import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { Constants } from '../../../actions'

const Auth0ProviderWithHistory = ( { children } ) => {
  const domain = Constants.AUTH0_DOMAIN
  const clientId = Constants.AUTH0_CLIENT_ID
  const audience = Constants.AUTH0_AUDIENCE

  const history = useHistory();

  const onRedirectCallback = ( appState ) => {
    history.push( appState?.returnTo || window.location.pathname )
  };

  const redirectUri = `${window.location.origin}`

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={redirectUri}
      audience={audience}
      onRedirectCallback={onRedirectCallback}>
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory
