// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".overlay_1nw5X {\n  opacity: 0;\n  transition: 0.15s ease-in;\n  background-color: rgba(0, 0, 0, 0.6);\n  position: fixed;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n  z-index: 1000;\n}\n.overlay_1nw5X.visible_AGotR {\n  opacity: 1;\n}\n.content_uBKzg {\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 50%;\n  min-width: 475px;\n  max-width: 550px;\n  height: 460px;\n  margin: auto;\n  background-color: #fff;\n  border-radius: 2px;\n  padding: 20px;\n  padding-bottom: 15px;\n}\n@media screen and (max-width: 500px) {\n  .content_uBKzg {\n    min-width: 0;\n    max-width: 100%;\n    width: 100%;\n  }\n}\n.buttons_3WNtF {\n  margin-top: 20px;\n  height: 65px;\n  width: 100%;\n  justify-content: flex-end;\n}\n", ""]);
// Exports
exports.locals = {
	"overlay": "overlay_1nw5X",
	"visible": "visible_AGotR",
	"content": "content_uBKzg",
	"buttons": "buttons_3WNtF"
};
module.exports = exports;
