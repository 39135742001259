// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".body_iYALd {\n  margin-top: 60px;\n  padding: 0 25px;\n  width: 100%;\n  max-width: 456px;\n  margin-left: auto;\n  margin-right: auto;\n}\n.scanText_3eEA2 {\n  color: #383F50;\n  font-size: 16px;\n  font-weight: 600;\n  padding: 0;\n}\n.noPadding_1Hnxo {\n  padding: 0;\n  margin: 0;\n}\n.errorText_13Hym {\n  color: #CB0A13;\n  font-size: 14px;\n  font-weight: 600;\n  margin-top: 16px;\n}\n.modalContent_1yTFa {\n  width: 70%;\n  margin-left: 35px;\n}\n.readyForUse_1ZH8y {\n  width: 100%;\n  background-color: #00A07B;\n  color: white;\n  font-size: 14px;\n  height: 40px;\n  line-height: 16px;\n}\n@media screen and (max-width: 600px) {\n  .modalIcon_2ks9e {\n    display: none;\n    width: 0;\n  }\n  .modalContent_1yTFa {\n    width: 100%;\n    margin-left: 0;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"body": "body_iYALd",
	"scanText": "scanText_3eEA2",
	"noPadding": "noPadding_1Hnxo",
	"errorText": "errorText_13Hym",
	"modalContent": "modalContent_1yTFa",
	"readyForUse": "readyForUse_1ZH8y",
	"modalIcon": "modalIcon_2ks9e"
};
module.exports = exports;
