import TagManager from 'react-gtm-module'
import { Config } from '../config';
import Util from '.'
import { encrypt } from './Encryption';

const { getSiteIdFromUrl } = Util.LocationUtil;
const gtmAnonymousUser = 'anonymous'

const gtmCode = Config.GOOGLE_TAG_MANAGER_ID;
const gtmIsValid = !!gtmCode;
if ( !gtmIsValid ) {
  console.error( 'The Google Tag Manager Id is missing' )
}

const gtmEvents = {
  association : 'association',
  disassociation : 'disassociation'
}

function gtmInit() {
  if ( gtmIsValid ) {
    const tagManagerArgs = {
      gtmId : gtmCode
    }
    TagManager.initialize( tagManagerArgs )
  }
}

function gtmEventPush( gtmEvent, gtmData ) {
  if ( !gtmIsValid ) {
    return
  }

  // Before send data to GTM encrypt Personally Identifiable Information (PII) (id: username)
  const userName = gtmData.id ? gtmData.id : ''
  const siteId = getSiteIdFromUrl()
  const userNameEncrypted = encrypt( userName )
  const gtmDataToSend = {
    ...gtmData,
    id : `UserEvent-${siteId}-${userNameEncrypted}`
  }

  // Send data to GTM
  if ( window?.dataLayer?.push ) {
    window.dataLayer.push( {
      event : gtmEvent,
      ...gtmDataToSend
    } )
  }
}

function gtmGetUsername( myProps ) {
  return ( myProps?.auth?.profile?.username ) ?
    myProps?.auth?.profile?.username :
    gtmAnonymousUser
}

export {
  gtmEventPush,
  gtmEvents,
  gtmGetUsername,
  gtmInit
}
