// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header_3Z-IG {\n  height: 108px;\n  padding: 20px 24px;\n  background-color: #F4F4FA;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);\n}\n.cancel_3ivba {\n  color: #383F50;\n  font-size: 14px;\n}\n.modeHeader_1E0C5 {\n  color: #4C82FC;\n}\n.error_1qGyH .cancel_3ivba,\n.error_1qGyH .modeHeader_1E0C5 {\n  color: white;\n}\n.row_8eD0_ {\n  width: 100%;\n  margin-bottom: 0;\n}\n.text_k_NJn {\n  font-size: 24px;\n  font-weight: bold;\n}\n.error_1qGyH {\n  color: white;\n  background-color: #C8273A;\n}\n.success_3bCR4 {\n  color: white;\n  background-color: #1ABE98;\n}\n.success_3bCR4 .modeHeader_1E0C5 {\n  color: white;\n}\n.rfa_3cL37 {\n  color: white;\n  background-color: #F5A623;\n}\n.rfa_3cL37 .cancel_3ivba,\n.rfa_3cL37 .modeHeader_1E0C5 {\n  color: white;\n}\n.errorSymbol_qraKl {\n  font-size: 27px;\n  font-weight: 300;\n  margin: 0;\n}\n.noSpace_1sKZv {\n  margin: 0;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "header_3Z-IG",
	"cancel": "cancel_3ivba",
	"modeHeader": "modeHeader_1E0C5",
	"error": "error_1qGyH",
	"row": "row_8eD0_",
	"text": "text_k_NJn",
	"success": "success_3bCR4",
	"rfa": "rfa_3cL37",
	"errorSymbol": "errorSymbol_qraKl",
	"noSpace": "noSpace_1sKZv"
};
module.exports = exports;
