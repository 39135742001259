// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".breadcrumbs_6m7zp {\n  font: var(--size-fontMedium) var(--fontFamily-primary);\n  padding: var(--size-padMedium) var(--size-padSmall);\n}\n.breadcrumbs_6m7zp > .link_19TaQ {\n  color: var(--color-brand1);\n  padding: 0px var(--size-padSmall);\n  outline: none;\n  display: block;\n  transition: background-color var(--time-medium);\n  white-space: nowrap;\n  background-color: transparent;\n  border-top-width: 0px;\n  border-left-width: 0px;\n  border-right-width: 0px;\n  border-bottom-width: 0px;\n}\n.breadcrumbs_6m7zp > .link_19TaQ:hover {\n  text-decoration: underline;\n}\n.breadcrumbs_6m7zp > .link_19TaQ:focus {\n  text-decoration: underline;\n}\n.breadcrumbs_6m7zp > .link_19TaQ:active {\n  background-color: var(--color-background3);\n}\n.breadcrumbs_6m7zp > .separator_1ssdQ {\n  color: var(--color-label3);\n  font-size: var(--size-fontSmall);\n  padding-left: var(--size-padSmall);\n  padding-right: var(--size-padSmall);\n}\n.breadcrumbs_6m7zp > .current_1x-Ne {\n  color: var(--color-label1);\n  padding-left: var(--size-padSmall);\n}\n.breadcrumbs_6m7zp.breadcrumbs-small_3IpwK {\n  font-size: var(--size-fontSmall);\n}\n", ""]);
// Exports
exports.locals = {
	"breadcrumbs": "breadcrumbs_6m7zp",
	"link": "link_19TaQ",
	"separator": "separator_1ssdQ",
	"current": "current_1x-Ne",
	"breadcrumbs-small": "breadcrumbs-small_3IpwK"
};
module.exports = exports;
