import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { Flex, Button, Text } from '@leverege/ui-elements'
import { UI, Constants } from '../../actions'

import S from './Home.less'

class Header extends React.Component {

  static propTypes = {
    ui : PropTypes.object.isRequired
  }

  performReset = ( currentMode, newMode ) => {
    if ( currentMode !== newMode ) {
      const { dispatch } = this.props
      dispatch( UI.multiSet( {
        tracker : {
          value : '',
          error : null,
          showError : false
        },
        asset : {
          value : '',
          error : null,
          showError : false
        }
      } ) )
    }
  }

  onPair = () => {
    const { dispatch, ui : { mode } } = this.props

    this.performReset( mode, Constants.MODE_PAIR )
    dispatch( UI.set( 'mode', Constants.MODE_PAIR ) )
    dispatch( push( '/tracker' ) )
  }

  onUnpair = () => {
    const { dispatch } = this.props
    const { ui : { mode } } = this.props
    this.performReset( mode, Constants.MODE_UNPAIR )
    dispatch( UI.set( 'mode', Constants.MODE_UNPAIR ) )
    dispatch( push( '/tracker' ) )
  }

  render() {
    return (
      <Flex direction="column" className={S.homeBody} align="flex-start" justify="flex-start">
        <Text variant="dialogTitle" data-automation="choose-an-action-label">
          Choose an action:
        </Text>
        <Flex variant="colL" style={{ width : '100%', marginTop : 10 }}>
          <Button onClick={this.onPair} variant="homeButton" data-automation="pair-device-button">
            Pair Device
          </Button>
          <Button onClick={this.onUnpair} variant="homeButtonHollow" data-automation="unpair-device-button">
            Unpair Device
          </Button>
        </Flex>
      </Flex>
    )
  }
}

export default connect( 
  state => ( {
    ui : state.ui
  } ) )( Header )
