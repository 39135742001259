// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".container_2_bzK {\n  width: 100%;\n  height: 100%;\n  position: absolute;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "container_2_bzK"
};
module.exports = exports;
