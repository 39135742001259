// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".textArea_2jBwb {\n  color: var(--color-label1);\n  border: 1px solid var(--color-border2);\n  padding: var(--size-medium) var(--size-padMedium) var(--size-medium) var(--size-medium);\n  outline: none;\n  font-size: var(--size-fontMedium);\n  transition: background-color 0.2s, color 0.2s, transform 0.2s;\n  border-radius: var(--size-small);\n  background-color: var(--color-white);\n}\n.textArea_2jBwb:focus {\n  border-top-color: var(--color-brand4);\n  border-left-color: var(--color-brand4);\n  border-right-color: var(--color-brand4);\n  border-bottom-color: var(--color-brand4);\n}\n.textArea_2jBwb[readonly] {\n  color: var(--color-disabled);\n  border-top-color: var(--color-disabled);\n  border-left-color: var(--color-disabled);\n  border-right-color: var(--color-disabled);\n  border-bottom-color: var(--color-disabled);\n}\n.textArea_2jBwb[data-error=true] {\n  border-top-color: var(--color-action2);\n  border-left-color: var(--color-action2);\n  border-right-color: var(--color-action2);\n  border-bottom-color: var(--color-action2);\n}\n.textArea_2jBwb::placeholder {\n  color: var(--color-disabled);\n  font-size: var(--size-fontSmall);\n  font-style: italic;\n}\n.textArea_2jBwb.textArea-small_1w-3K {\n  padding: var(--size-small) var(--size-medium);\n  font-size: var(--size-fontSmall);\n}\n.textArea_2jBwb.textArea-modal_1ZAAp {\n  border-top-color: var(--color-border4);\n  border-left-color: var(--color-border4);\n  border-right-color: var(--color-border4);\n  border-bottom-color: var(--color-border4);\n}\n.textArea_2jBwb.textArea-modal_1ZAAp[data-error=true] {\n  border-top-color: var(--color-alertDangerA);\n  border-left-color: var(--color-alertDangerA);\n  border-right-color: var(--color-alertDangerA);\n  border-bottom-color: var(--color-alertDangerA);\n}\n", ""]);
// Exports
exports.locals = {
	"textArea": "textArea_2jBwb",
	"textArea-small": "textArea-small_1w-3K",
	"textArea-modal": "textArea-modal_1ZAAp"
};
module.exports = exports;
