// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".actionButton_3SzC7 {\n  color: white;\n  height: 48px;\n  width: 100%;\n  margin-bottom: 20px;\n  border-radius: 3px;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);\n  border: none;\n}\n.blue_2z0JC {\n  background-color: #4C82FC;\n}\n.container_1wJlE {\n  padding: 48px 24px;\n  width: 100%;\n  max-width: 456px;\n  margin-left: auto;\n  margin-right: auto;\n}\n.row_aEPJv {\n  width: 100%;\n}\n.autoRight_1yCYf {\n  color: #383F50;\n  font-size: 20px;\n  font-weight: bold;\n}\n.rfaCopy_Bifzb {\n  color: #383F50;\n  font-size: 16px;\n  font-weight: bold;\n}\n.label_14aat {\n  color: #585A6E;\n  font-size: 18px;\n}\n.rfaLabel_3LVhr {\n  display: flex;\n  align-items: center;\n  background: #F5A623;\n  padding: 4px 8px;\n  border-radius: 5px;\n  font-size: 18px;\n  font-weight: 500;\n  color: white;\n}\n.rfaIcon_RtdOZ {\n  margin-right: 6px;\n}\n.summaryText_3495m {\n  color: #383F50;\n  font-size: 18px;\n  font-weight: 600;\n}\n.trackerHealthRFA_3Cy5j {\n  color: red;\n}\n", ""]);
// Exports
exports.locals = {
	"actionButton": "actionButton_3SzC7",
	"blue": "blue_2z0JC",
	"container": "container_1wJlE",
	"row": "row_aEPJv",
	"autoRight": "autoRight_1yCYf",
	"rfaCopy": "rfaCopy_Bifzb",
	"label": "label_14aat",
	"rfaLabel": "rfaLabel_3LVhr",
	"rfaIcon": "rfaIcon_RtdOZ",
	"summaryText": "summaryText_3495m",
	"trackerHealthRFA": "trackerHealthRFA_3Cy5j"
};
module.exports = exports;
