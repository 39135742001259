import React from 'react'
import { Wave } from 'better-react-spinkit'
import { Flex } from '@leverege/ui-elements'

import S from './LoadingScreen.less'

class LoadingScreen extends React.Component {

  render() {
    return (
      <Flex className={S.container} justify="center" align="center">
        <Wave size={100} color="#2C62FF" />
      </Flex>
    )
  }
}

export default LoadingScreen
