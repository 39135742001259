module.exports = {
  hierarchy : {
    horizontal : [
      'dataBlock-horizontal'
    ],
    vinInfo : [
      'dataBlock-vinInfo'
    ],
    exceptionDetail : [
      'dataBlock-exceptionDetail'
    ]
  },
  props : {
    default : {},
    horizontal : {
      vertical : false
    },
    vinInfo : {},
    exceptionDetail : {}
  }
}
