module.exports = {
  hierarchy : {
    small : [
      'textInput-small'
    ],
    search : [
      'textInput-search'
    ],
    searchFixed : [
      'textInput-search',
      'textInput-searchFixed'
    ],
    searchMobile : [
      'textInput-search',
      'textInput-searchMobile'
    ],
    settingsInput : [
      'textInput-settingsInput'
    ],
    contactFormInput : [
      'textInput-contactFormInput'
    ],
    paginationInput : [
      'textInput-paginationInput'
    ],
    filter : [
      'textInput-filter'
    ],
    alertSetter : [
      'textInput-alertSetter'
    ],
    scannable : [
      'textInput-scannable'
    ],
    ruleNumeric : [
      'textInput-ruleNumeric'
    ]
  },
  props : {
    default : {},
    small : {},
    search : {},
    searchFixed : {},
    searchMobile : {},
    settingsInput : {},
    contactFormInput : {},
    paginationInput : {},
    filter : {},
    alertSetter : {},
    scannable : {},
    ruleNumeric : {}
  }
}
