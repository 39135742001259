import { push } from 'connected-react-router'
import axios from 'axios'
import { Accessor } from '@cox2m/pinpoint-accessor'

import Constants from './Constants'
import Util from '../util'
import LoginActions from './LoginActions'
import { gtmGetUsername, gtmEventPush, gtmEvents } from '../util/GoogleTagManager'

const { getSiteIdFromUrl } = Util.LocationUtil

/**
 * Associate/dissociate tracker 
 */
function associate( logout ) {
  return async ( dispatch, getState ) => {
    dispatch( { type : Constants.ASSOC_PENDING } )
    const { ui } = getState()
    const { mode } = ui
    const trackerId = `${ui?.tracker?.value}` || undefined
    const assetId = ui.asset.value || undefined
    const token = localStorage.getItem( 'id_token' )

    let result
    try {
      let assocUrl = 'v1/association';
      let postData = { assetId, trackerId, siteId : getSiteIdFromUrl() }

      if ( mode === Constants.MODE_PAIR ) {
        postData = { ...postData, ...{ vin : assetId } }
      } else if ( mode === Constants.MODE_UNPAIR ) {
        assocUrl = 'v1/disassociation'
      }
      const assocResult = await axios.post(
        `${process.env.HOST}/${assocUrl}`,
        postData,
        {
          headers : {
            Authorization : `Bearer ${token}`
          }
        }
      )

      result = { type : Constants.ASSOC_SUCCESS, assocResult : assocResult.data }

      const { type } = assocResult.data

      const gtmData = {
        id : gtmGetUsername( getState() )
      }

      gtmEventPush( 
        type === 'disassociate' ? gtmEvents.disassociation : gtmEvents.association,
        gtmData )

      const assoc = await axios.get(
        `${process.env.HOST}/v1/association?siteId=${getSiteIdFromUrl()}&trackerId=${trackerId}`,
        {
          headers : {
            Authorization : `Bearer ${token}`
          }
        }
      )

      if ( type === 'disassociate' ) {
        const trackerHealth = Accessor.calculateTrackerHealth( assoc.data )
        dispatch( { type : Constants.UI_SET_KEY, key : 'trackerHealth', value : trackerHealth } )
      }

    } catch ( error ) {
      if ( error.response?.data?.status === 403 ) {
        if ( Constants.IS_AUTH0_ENABLED ) {
          LoginActions.logoutAuth0( logout, dispatch )
        } else {
          dispatch( LoginActions.logout() )
        }
      }
      result = { type : Constants.ASSOC_FAILURE, error : error.response.data }
    }
    dispatch( result )

    // If dissociating, check RFA status now
    if ( result.type === Constants.ASSOC_SUCCESS ) {
      if ( mode === Constants.MODE_UNPAIR ) {
        try {
          await dispatch( rfa() )
        } catch ( error ) {
          console.error( error )
        }
        dispatch( push( '/success' ) )
      } else {
        dispatch( push( '/success' ) )
      }
    } else {
      dispatch( push( '/failure' ) )
    }
    return result
  }
}

/**
 * Get RFA status of tracker (sets event too that why it is a post)
 */
function rfa() {
  return async ( dispatch, getState ) => {
    
    dispatch( { type : Constants.RFA_PENDING } )
    // dispatch( { type : Constants.RFA_FAILURE, error : { status : 500, rfaStatus : 'readyforUse' } } )
    // throw 'new Error'
    const { ui } = getState()
    const trackerId = `${ui.tracker.value}` || null
    const token = localStorage.getItem( 'id_token' )

    let result
    try {
      const rfaResult = await axios.post(
        `${process.env.HOST}/v1/rfa/site/${getSiteIdFromUrl()}/tracker/${trackerId}`,
        undefined,
        {
          headers : {
            Authorization : `Bearer ${token}`
          }
        }
      )
      const { rfaStatus } = rfaResult.data

      if ( rfaStatus === Constants.rfaStatuses.readyForUse.status ) {
        result = { type : Constants.RFA_SUCCESS_READY_FOR_USE, rfaResult : rfaResult.data }
      } else {
        result = { type : Constants.RFA_SUCCESS_IN_RFA, rfaResult : rfaResult.data }
      }

    } catch ( error ) {
      console.error( error )
      result = { type : Constants.RFA_FAILURE, error : error.response.data }
    }
    dispatch( result )
    return result
  }
}

function getVinInfo( vin ) {
  return async () => {
    const token = localStorage.getItem( 'id_token' )
    const res = await axios.get(
      `${process.env.HOST}/v1/vin?siteId=${getSiteIdFromUrl()}&vin=${vin}`,
      {
        headers : {
          Authorization : `Bearer ${token}`
        }
      }
    )
    return Object.values( res.data ).every( val => val === '' ) ? null : res.data
  }
}

/**
 * Check RFA status before association/pairing a tracker and assetId (vin)
 */
function checkRFABeforeAssociation() {
  return async ( dispatch, getState, { pinpointApi } ) => {
    dispatch( push( '/asset' ) )
  }
}

exports.associate = associate
exports.getVinInfo = getVinInfo
exports.checkRFABeforeAssociation = checkRFABeforeAssociation
