// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".slider_2mjjt {\n  width: 100px;\n  height: 20px;\n  outline: none;\n  border-radius: 20px;\n  background-color: var(--color-background3);\n  -webkit-appearance: none;\n}\n.slider_2mjjt:focus {\n  background-color: var(--color-brand3);\n}\n.slider_2mjjt:hover {\n  background-color: var(--color-brand2);\n}\n.slider_2mjjt:active {\n  background-color: var(--color-brand4);\n}\n.slider_2mjjt[data-disabled=true] {\n  background-color: var(--color-disabled);\n}\n.slider_2mjjt::-webkit-slider-thumb {\n  width: 18px;\n  height: 18px;\n  outline: none;\n  margin-top: 1px;\n  margin-left: 1px;\n  margin-right: 1px;\n  border-radius: 50%;\n  margin-bottom: 1px;\n  background-color: var(--color-white);\n  -webkit-appearance: none;\n}\n.slider_2mjjt::-moz-range-thumb {\n  width: 18px;\n  height: 18px;\n  outline: none;\n  margin-top: 1px;\n  margin-left: 1px;\n  margin-right: 1px;\n  border-radius: 50%;\n  margin-bottom: 1px;\n  background-color: var(--color-white);\n  -webkit-appearance: none;\n}\n", ""]);
// Exports
exports.locals = {
	"slider": "slider_2mjjt"
};
module.exports = exports;
