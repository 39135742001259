import Constants from '../actions/Constants'

const initialState = {
  assoc : {
    status : null,
    error : null,
    result : null,
  },
  rfa : {
    status : null,
    error : null,
    result : null,
  }
}

function pinpoint( state = initialState, action ) {
  switch ( action.type ) {
    case Constants.ASSOC_PENDING: {
      const nState = { ...state }
      const nAssoc = { 
        ...nState.assoc,
        status : Constants.ASSOC_PENDING,
        result : null,
        error : null
      }
      return {
        ...nState,
        assoc : nAssoc
      }
    }
    case Constants.ASSOC_SUCCESS: {
      const nState = { ...state }
      const nAssoc = { 
        ...nState.assoc,
        status : Constants.ASSOC_SUCCESS,
        result : action.assocResult,
        error : null
      }
      return {
        ...nState,
        assoc : nAssoc
      }
    }
    case Constants.ASSOC_FAILURE: {
      const nState = { ...state }
      const nAssoc = { 
        ...nState.assoc,
        status : Constants.ASSOC_FAILURE,
        result : null,
        error : action.error
      }
      return {
        ...nState,
        assoc : nAssoc
      }
    }
    case Constants.RFA_PENDING: {
      const nState = { ...state }
      const nRFA = { 
        ...nState.rfa,
        status : Constants.RFA_PENDING,
        result : null,
        error : null
      }
      return {
        ...nState,
        rfa : nRFA
      }
    }
    case Constants.RFA_SUCCESS_READY_FOR_USE: {
      const nState = { ...state }
      const nRFA = { 
        ...nState.rfa,
        status : Constants.RFA_SUCCESS_READY_FOR_USE,
        result : action.rfaResult,
        error : null
      }
      return {
        ...nState,
        rfa : nRFA
      }
    }
    case Constants.RFA_SUCCESS_IN_RFA: {
      const nState = { ...state }
      const nRFA = { 
        ...nState.rfa,
        status : Constants.RFA_SUCCESS_IN_RFA,
        result : action.rfaResult,
        error : null
      }
      return {
        ...nState,
        rfa : nRFA
      }
    }
    case Constants.RFA_FAILURE: {
      const nState = { ...state }
      const nRFA = { 
        ...nState.rfa,
        status : Constants.RFA_FAILURE,
        result : null,
        error : action.error
      }
      return {
        ...nState,
        rfa : nRFA
      }
    }
    default:
      return state
  }
}

export default pinpoint
