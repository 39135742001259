// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".homeBody_4qzwO {\n  width: 100%;\n  max-width: 456px;\n  padding: 78px 25px;\n  margin-left: auto;\n  margin-right: auto;\n}\n.chooseText_15zAY {\n  color: #00305E;\n  font-size: 18px;\n  margin-bottom: 10px;\n}\n.blue_7SNjs {\n  background-color: #4C82FC;\n}\n.gray_19Ky2 {\n  background-color: #5B6888;\n}\n.homeButton_3M09Y {\n  font-size: 18px;\n  font-weight: 5s00;\n  color: #fff;\n  width: 100%;\n  border: none;\n  border-radius: 3px;\n  height: 96px;\n  margin-bottom: 32px;\n}\n", ""]);
// Exports
exports.locals = {
	"homeBody": "homeBody_4qzwO",
	"chooseText": "chooseText_15zAY",
	"blue": "blue_7SNjs",
	"gray": "gray_19Ky2",
	"homeButton": "homeButton_3M09Y"
};
module.exports = exports;
