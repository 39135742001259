module.exports = {
  hierarchy : {
    history : [
      'dateTimeRangePicker-history'
    ],
    analytics : [
      'dateTimeRangePicker-analytics'
    ],
    CoreyTest : [
      'dateTimeRangePicker-CoreyTest'
    ]
  },
  props : {
    default : {
      rangeChangeType : 'start'
    },
    history : {
      rangeChangeType : 'start'
    },
    analytics : {
      rangeChangeType : 'start'
    },
    CoreyTest : {
      rangeChangeType : 'start',
      format : null
    }
  }
}
