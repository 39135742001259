import { Config } from '../config'

function getParams( query ) {
  if ( !query ) {
    return { };
  }

  return ( /^[?#]/.test( query ) ? query.slice( 1 ) : query )
    .split( '&' )
    .reduce( ( params, param ) => {
      const [ key, value ] = param.split( '=' );
      params[key] = value ? decodeURIComponent( value.replace( /\+/g, ' ' ) ) : '';
      return params;
    }, { } );
}

function getQueryParams() {
  return getParams( window.location.search );
}

function getQueryParam( name ) {
  const p = getQueryParams();
  return p && p[name];
}

function clearQueryParams() {
  window.history.replaceState( {}, document.title, '/' )
}

function getSiteIdFromUrl() {
  if ( Config.SITE_ID ) {
    return Config.SITE_ID
  }
  let siteId = window.location.hostname.split( '.' )
  siteId = Array.isArray( siteId ) ? siteId[0] : null
  siteId = siteId ? siteId.split( '-' ) : null
  siteId = Array.isArray( siteId ) ? siteId[siteId.length - 1] : null
  siteId = siteId || 'lwc'
  return siteId
}

function getSystemFromUrl() {
  return `@${getSiteIdFromUrl()}`
}

function getFeedbackSystemFromUrl() {
  return `${getSystemFromUrl()}-feedback`
}

module.exports = {
  getParams,
  getQueryParam,
  getQueryParams,
  clearQueryParams,
  getSystemFromUrl,
  getSiteIdFromUrl,
  getFeedbackSystemFromUrl
};
