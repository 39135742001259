// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".radioButton_oE__o {\n  width: 32px;\n  color: var(--color-brand1);\n  height: 32px;\n  font-size: var(--size-fontMedium);\n}\n.radioButton_oE__o .appearance_25TtW {\n  border: 1px solid var(--color-brand1);\n  outline: none;\n  transition: background-color 0.2s, color 0.2s, transform 0.2s;\n  border-radius: 100%;\n  background-color: transparent;\n}\n.radioButton_oE__o .appearance_25TtW .icon_39R5L {\n  transition: background-color 0.2s, color 0.2s, transform 0.2s;\n}\n.radioButton_oE__o[data-value=true] {\n  color: var(--color-white);\n}\n.radioButton_oE__o[data-value=true] .appearance_25TtW {\n  background-color: var(--color-action1);\n}\n.radioButton_oE__o[data-value=true] input:focus ~ .appearance_25TtW {\n  background-color: var(--color-brand2);\n}\n.radioButton_oE__o:hover input ~ .appearance_25TtW {\n  background-color: var(--color-brand3);\n}\n.radioButton_oE__o:active[data-value=true] .appearance_25TtW {\n  background-color: var(--color-brand4);\n}\n.radioButton_oE__o[disabled] .appearance_25TtW {\n  border: 1px solid var(--color-disabled);\n}\n.radioButton_oE__o[disabled][data-value=true] .appearance_25TtW {\n  background-color: var(--color-disabled);\n}\n.radioButton_oE__o.radioButton-small_1Y-Lc {\n  width: 20px;\n  height: 20px;\n  font-size: var(--size-fontSmall);\n}\n", ""]);
// Exports
exports.locals = {
	"radioButton": "radioButton_oE__o",
	"appearance": "appearance_25TtW",
	"icon": "icon_39R5L",
	"radioButton-small": "radioButton-small_1Y-Lc"
};
module.exports = exports;
