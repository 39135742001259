import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import ui from './UIReducer'
import auth from './LoginReducer'
import pinpoint from './PinpointReducer'

const reducers = ( { history } ) => combineReducers( {
  ui,
  auth,
  pinpoint,
  router : connectRouter( history )
} )

module.exports = reducers
