import CryptoJS from 'crypto-js'
import { Config } from '../config';

const encryptionSalt = Config.ENCRYPTION_SALT
const encryptionPhrase = Config.ENCRYPTION_PHRASE
const encryptionIsValid = encryptionSalt && encryptionPhrase;
if ( !encryptionIsValid ) {
  console.error( 'The Encryption process is missing the salt and/or the phrase' )
}

function generateKey( passPhrase = encryptionPhrase, salt = encryptionSalt, keySize = 128, iterationCount = 1000 ) {
  return CryptoJS.PBKDF2(
    passPhrase,
    CryptoJS.enc.Utf8.parse( salt ),
    { keySize : keySize / 32, iterations : iterationCount } );
}

function decrypt( cipherText ) {
  const key = generateKey();
  const cipherParams = CryptoJS.lib.CipherParams.create( {
    ciphertext : CryptoJS.enc.Base64.parse( cipherText )
  } );
  const decrypted = CryptoJS.AES.decrypt(
    cipherParams,
    key,
    { mode : CryptoJS.mode.ECB } );
  return decrypted.toString( CryptoJS.enc.Utf8 ).toString();
}

function encrypt( plainText ) {
  const key = generateKey( encryptionPhrase );
  const encrypted = CryptoJS.AES.encrypt(
    plainText,
    key,
    { mode : CryptoJS.mode.ECB } );
  return encrypted.ciphertext.toString( CryptoJS.enc.Base64 ).toString();
}

export {
  encrypt,
  decrypt
}
