// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header_3FDdb {\n  height: 108px;\n  padding: 0 24px;\n  background-color: #F4F4FA;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.08);\n}\n.logo_-KUZ_ {\n  height: 70%;\n}\n.logOut_1_YZ3 {\n  color: #4C82FC;\n  font-size: 14px;\n  font-weight: 600;\n}\n", ""]);
// Exports
exports.locals = {
	"header": "header_3FDdb",
	"logo": "logo_-KUZ_",
	"logOut": "logOut_1_YZ3"
};
module.exports = exports;
