// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".actionButton_pSdEQ {\n  color: white;\n  height: 48px;\n  width: 100%;\n  margin-bottom: 20px;\n  border-radius: 3px;\n  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);\n  border: none;\n}\n.blue_1ybKy {\n  background-color: #4C82FC;\n}\n.container_3zwZ_ {\n  padding: 48px 24px;\n  width: 100%;\n  max-width: 456px;\n  margin-left: auto;\n  margin-right: auto;\n}\n.resultText_1_T5- {\n  color: black;\n  font-size: 18px;\n  font-weight: 600;\n  margin-bottom: 56px;\n}\n", ""]);
// Exports
exports.locals = {
	"actionButton": "actionButton_pSdEQ",
	"blue": "blue_1ybKy",
	"container": "container_3zwZ_",
	"resultText": "resultText_1_T5-"
};
module.exports = exports;
