import React from 'react'
import { Flex, Button } from '@leverege/ui-elements'
import S from './Modal.less'

export default class Modal extends React.Component {
  componentDidMount() {
    document.addEventListener( 'keydown', this.handleKeyPress, false );
    const overlay = document.getElementsByClassName( S.overlay )[0]
    setTimeout( () => overlay.classList.add( S.visible ), 10 )
  }

  componentWillUnmount() {
    document.removeEventListener( 'keydown', this.handleKeyPress, false );
  }  

  toggleModal = ( e ) => {
    const { onCancel } = this.props
    onCancel() 
  }

  handleOverlayClick = ( e ) => {
    // fire toggleModal only if overlay itself, and not child window is clicked    
    if ( e.target.classList.contains( S.overlay ) ) {
      this.toggleModal();
    }
  }

  handleKeyPress = ( e ) => {
    const { cancelLabel } = this.props
    const cancelIsDefined = !!cancelLabel
    if ( e.keyCode === 27 ) {
      // close modal on escape press
      this.toggleModal();
    } else if ( e.keyCode === 9 ) {
      e.preventDefault();
      // keep focus on dialog buttons when tabbing
      if ( cancelIsDefined && document.activeElement.id === S.cancel ) {
        document.getElementById( S.confirm ).focus()
      } else if ( cancelIsDefined ) {
        document.getElementById( S.cancel ).focus()
      }
    }
  }

  render() {
    const { onCancel, disableConfirm = 'false', onConfirm, cancelLabel, confirmLabel, children, ...rest } = this.props
    let { className } = this.props
    if ( className ) {
      className += ' '
    } else {
      className = ''
    }
    let cancel;
    if ( onCancel && cancelLabel ) {
      cancel = <Button data-automation="modal-cancel-button" variant="modalCancel" id={S.cancel} onClick={onCancel}>{cancelLabel}</Button>
    }

    return (
      <div className={className + S.overlay} {...rest} onClick={this.handleOverlayClick}>
        <Flex className={S.content} direction="column" justify="space-between">
          { children }
          <Flex variant="noSpace" align="center" justify="flex-end">
            { cancel }
            <Button data-automation="modal-submit-button" variant="modalConfirm" id={S.confirm} disabled={disableConfirm} onClick={onConfirm || onCancel}>
              {confirmLabel}
            </Button>
          </Flex>
        </Flex>
      </div>
    )
  }
}
