module.exports = {
  hierarchy : {
    small : [
      'breadcrumbs-small'
    ]
  },
  props : {
    default : {
      separatorIcon : 'fa fa-arrow-right'
    },
    small : {
      separatorIcon : 'fa fa-arrow-right'
    }
  }
}
