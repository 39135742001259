module.exports = {
  hierarchy : {
    hollow : [
      'dateTimePicker-hollow'
    ]
  },
  props : {
    default : {},
    hollow : {}
  }
}
