// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dataBlock_1HMb9 {\n  border: 1px solid var(--color-border1);\n  padding: var(--size-padLarge);\n  box-shadow: 0 4px 8px var(--color-shadow2);\n  border-radius: var(--size-padMedium);\n}\n.dataBlock_1HMb9 .title_2lwaR {\n  font-weight: bold;\n  margin-bottom: var(--size-padSmall);\n}\n.dataBlock_1HMb9 .row_2IC_l {\n  font-size: var(--size-fontMedium);\n  margin-top: var(--size-padSmall);\n  margin-bottom: var(--size-padSmall);\n}\n.dataBlock_1HMb9 .row_2IC_l .value_3Jd3T {\n  font-weight: bold;\n}\n.dataBlock_1HMb9.dataBlock-horizontal_2pq3g .row_2IC_l {\n  width: 33%;\n  display: flex;\n  align-items: flex-start;\n  flex-direction: column;\n  justify-content: flex-start;\n}\n.dataBlock_1HMb9.dataBlock-horizontal_2pq3g .row_2IC_l .label_sdRIS {\n  color: var(--color-label3);\n  font-size: var(--size-fontSmall);\n  margin-right: var(--size-padMedium);\n  text-transform: uppercase;\n}\n.dataBlock_1HMb9.dataBlock-vinInfo_3eiBI {\n  margin-top: 20px;\n  box-shadow: none;\n  border-top: 1px solid var(--color-border1);\n  border-left: none;\n  border-right: none;\n  border-bottom: 1px solid var(--color-border1);\n  border-radius: 0px;\n}\n.dataBlock_1HMb9.dataBlock-exceptionDetail_38xFd {\n  border: none;\n  padding: 0px 0px 16px;\n  box-shadow: none;\n}\n.dataBlock_1HMb9.dataBlock-exceptionDetail_38xFd .row_2IC_l {\n  padding-top: var(--size-padSmall);\n  padding-bottom: var(--size-padSmall);\n}\n.dataBlock_1HMb9.dataBlock-exceptionDetail_38xFd .row_2IC_l .label_sdRIS {\n  color: var(--color-label1);\n  padding: 0px;\n  font-size: 14px;\n  font-weight: 500;\n  line-height: 19px;\n}\n.dataBlock_1HMb9.dataBlock-exceptionDetail_38xFd .row_2IC_l .value_3Jd3T {\n  color: var(--color-label1);\n  font-weight: 600;\n}\n", ""]);
// Exports
exports.locals = {
	"dataBlock": "dataBlock_1HMb9",
	"title": "title_2lwaR",
	"row": "row_2IC_l",
	"value": "value_3Jd3T",
	"dataBlock-horizontal": "dataBlock-horizontal_2pq3g",
	"label": "label_sdRIS",
	"dataBlock-vinInfo": "dataBlock-vinInfo_3eiBI",
	"dataBlock-exceptionDetail": "dataBlock-exceptionDetail_38xFd"
};
module.exports = exports;
