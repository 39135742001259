import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withAuth0 } from '@auth0/auth0-react'
import { Flex, Text } from '@leverege/ui-elements'
import { Config } from '../../config'
import { Constants, LoginActions } from '../../actions'

import S from './Header.less'

class Header extends React.Component {

  static contextTypes = {
    store : PropTypes.object
  }

  onClick = () => {
    const { dispatch, auth0 } = this.props
    
    if ( Constants.IS_AUTH0_ENABLED ) {
      LoginActions.logoutAuth0( auth0.logout, dispatch )
    } else {
      dispatch( LoginActions.logout() )
    }
  }

  render() {
    return (
      <Flex className={S.header} justify="space-between" align="center">
        <img 
          alt="Cox2M Logo" 
          className={S.logo} 
          src={`${Constants.uiLogosBucketUrl}${Config.PROJECT_NAME}-logo.png`}
          data-automation="cox2m-logo" />
        <Text onClick={this.onClick} className={S.logOut} data-automation="logout-button">Log Out</Text>
      </Flex>
    )
  }
}

export default connect( )( withAuth0( Header ) )
