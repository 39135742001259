module.exports = {
  hierarchy : {
    small : [
      'combobox-small'
    ]
  },
  props : {
    default : {
      selectIcon : 'fa fa-chevron-down'
    },
    small : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    }
  }
}
