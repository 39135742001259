module.exports = {
  hierarchy : {
    toggleSettings : [
      'toggle-toggleSettings'
    ],
    showOnMap : [
      'toggle-showOnMap'
    ],
    green : [
      'toggle-green'
    ]
  },
  props : {
    default : {},
    toggleSettings : {},
    showOnMap : {},
    green : {}
  }
}
