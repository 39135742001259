import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import Path from '@leverege/path'
import { Content, Flex, Button, Text } from '@leverege/ui-elements'

import S from './RFA.less'
import ScanFooter from '../scanFooter/ScanFooter'
import { UI, Constants } from '../../actions'

class RFA extends React.Component {

  static contextTypes = {
    store : PropTypes.object
  };

  static propTypes = {
    rfaState : PropTypes.object.isRequired
  };

  componentDidMount() {
    const { dispatch } = this.props
    const text = 'Tracker in RFA'
    dispatch(
      UI.set(
        'scanHeader',
        {
          variant : 'rfa',
          text,
          cancel : true
        }
      )
    )
  }

  onDone = () => {
    const { dispatch } = this.props

    dispatch( UI.multiSet( {
      tracker : {
        value : '',
        error : null,
        showError : false
      },
      asset : {
        value : '',
        error : null,
        showError : false
      }
    } ) )
    dispatch( push( '/' ) )
  }

  onBack = () => {
    const { dispatch } = this.props
    dispatch( push( '/tracker' ) )
  }

  render() {
    const { rfaState } = this.props
    const rfaStatus = Path.get( 'result/rfaStatus', rfaState, Constants.rfaStatuses.readyForUse.status )
    const rfaCopy = Constants.rfaStatuses[rfaStatus].copy
    
    return (
      <Content>
        <Content.Area>
          <Flex direction="column" className={S.container}>
            <Text className={S.resultText}>
              {rfaCopy}
            </Text>
            <Button onClick={this.onDone} className={classnames( S.actionButton, S.blue )}>
              Done
            </Button>
          </Flex>
        </Content.Area>
        <Content.Footer className={S.footer}>
          <ScanFooter onBack={this.onBack} showBack showNext={false} progress={{ progress : 1, total : 3 }} />
        </Content.Footer>
      </Content>
    )
  }
}

export default connect( 
  state => ( {
    rfaState : state.pinpoint.rfa
  } )
)( RFA )
