module.exports = {
  hierarchy : {
    small : [
      'multiSelect-small'
    ],
    contacts : [
      'multiSelect-contacts'
    ],
    ruleRecipients : [
      'multiSelect-ruleRecipients'
    ],
    dmsStatuses : [
      'multiSelect-contacts',
      'multiSelect-dmsStatuses'
    ]
  },
  props : {
    default : {
      selectIcon : 'fa fa-chevron-down'
    },
    small : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    contacts : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    ruleRecipients : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    },
    dmsStatuses : {
      selectIcon : 'fa fa-chevron-down',
      icons : null
    }
  }
}
