module.exports = {
  hierarchy : {
    small : [
      'list-small'
    ],
    filter : [
      'list-filter'
    ],
    selectableItemList : [
      'list-selectableItemList'
    ]
  },
  props : {
    default : {
      icon : 'fa fa-check'
    },
    small : {
      icon : 'fa fa-check'
    },
    filter : {
      icon : 'fa fa-check'
    },
    selectableItemList : {
      icon : 'fa fa-check'
    }
  }
}
