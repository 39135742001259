// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".toggle_39kxc {\n  width: 56px;\n  height: 28px;\n  outline: none;\n}\n.toggle_39kxc .background_1Kh2K {\n  height: 100%;\n  border-radius: 28px;\n  background-color: var(--color-background3);\n}\n.toggle_39kxc:hover .background_1Kh2K {\n  background-color: var(--color-background8);\n}\n.toggle_39kxc[data-disabled=true] .background_1Kh2K {\n  background-color: var(--color-disabled);\n}\n.toggle_39kxc[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand1);\n}\n.toggle_39kxc:focus[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand3);\n}\n.toggle_39kxc:hover[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand6);\n}\n.toggle_39kxc[data-disabled=true][data-value=true] .background_1Kh2K {\n  background-color: var(--color-disabled);\n}\n.toggle_39kxc:active[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand4);\n}\n.toggle_39kxc .handle_1YzNF {\n  width: 24px;\n  margin: 2px;\n  height: 24px;\n  outline: none;\n  border-radius: 50%;\n  background-color: var(--color-white);\n}\n.toggle_39kxc[data-value=true] .handle_1YzNF {\n  transform: translate(28px, 0);\n}\n.toggle_39kxc.toggle-showOnMap_bksH7 .background_1Kh2K {\n  background-color: var(--color-background8);\n}\n.toggle_39kxc.toggle-showOnMap_bksH7[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand1);\n}\n.toggle_39kxc.toggle-showOnMap_bksH7:hover[data-value=true] .background_1Kh2K {\n  background-color: var(--color-brand2);\n}\n.toggle_39kxc.toggle-green_2iRic {\n  margin-left: 10px;\n  margin-right: 10px;\n}\n.toggle_39kxc.toggle-green_2iRic[data-value=true] .background_1Kh2K {\n  background-color: #00d47f;\n}\n", ""]);
// Exports
exports.locals = {
	"toggle": "toggle_39kxc",
	"background": "background_1Kh2K",
	"handle": "handle_1YzNF",
	"toggle-showOnMap": "toggle-showOnMap_bksH7",
	"toggle-green": "toggle-green_2iRic"
};
module.exports = exports;
