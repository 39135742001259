module.exports = {
  hierarchy : {
    small : [
      'textArea-small'
    ],
    modal : [
      'textArea-modal'
    ]
  },
  props : {
    default : {},
    small : {},
    modal : {}
  }
}
