import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Content } from '@leverege/ui-elements'
import { Route, Switch } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import Header from './components/header/Header'
import ScanHeader from './components/scanHeader/ScanHeader'
import Home from './components/home/Home'
import ScanTracker from './components/scanTracker/ScanTracker'
import ScanAsset from './components/scanAsset/ScanAsset'
import LoginScreen from './components/login/LoginScreen'
import AssocSuccess from './components/assocSuccess/AssocSuccess'
import AssocFailure from './components/assocFailure/AssocFailure'
import RFA from './components/rfa/RFA'
import RFAFailure from './components/rfaFailure/RFAFailure'
import LoadingScreen from './components/loadingScreen/LoadingScreen'
import { LoginActions, Constants } from './actions'
import Util from './util'

export default function App() {
  const dispatch = useDispatch()
  const router = useSelector( state => state.router )
  const auth = useSelector( state => state.auth )
  const { isLoading, isAuthenticated, user, getAccessTokenSilently, logout } = useAuth0()
  const siteId = Util.LocationUtil.getSiteIdFromUrl()

  const localIdToken = localStorage.getItem( 'id_token' )

  async function getClaims() {
    const token = localIdToken || await getAccessTokenSilently()
    LoginActions.verifyAuth0( token, isAuthenticated, user, dispatch )
  }

  useEffect( () => {
    if ( Constants.IS_AUTH0_ENABLED ) {
      if ( !isAuthenticated ) return
      
      // check if the current user has permission to enter the app
      const permissions = LoginActions.getAuth0Metadata( user )?.permissions || []
      if ( !LoginActions.hasPermission( permissions, siteId ) ) {
        LoginActions.logoutAuth0( logout, dispatch )
      } else {
        getClaims()
      }
    }
  }, [ isAuthenticated ] )

  if ( LoginActions.isVerifying( auth ) ) {
    return <LoadingScreen />
  }

  if ( Constants.IS_AUTH0_ENABLED && isLoading ) {
    return <LoadingScreen />
  }

  if ( LoginActions.isLoggingIn( auth ) || !LoginActions.isLoggedIn( auth ) ) {
    return <LoginScreen />
  }

  return (
    <Content>
      <Content.Header>
        <Switch location={router.location}>
          <Route
            component={ScanHeader}
            path={[ '/asset', '/tracker', '/success', '/failure', '/rfa', '/rfaFailure' ]} />
          <Route component={Header} />
        </Switch>
      </Content.Header>
      <Content.Area>
        <Switch location={router.location}>
          <Route render={props => <ScanAsset allowInvalidVin={siteId === 'lab'} />} path="/asset" />
          <Route component={ScanTracker} path="/tracker" />
          <Route component={AssocSuccess} path="/success" />
          <Route component={AssocFailure} path="/failure" />
          <Route component={RFA} path="/rfa" />
          <Route component={RFAFailure} path="/rfaFailure" />
          <Route component={Home} />
        </Switch>
      </Content.Area>
    </Content>
  )
}
