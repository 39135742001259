// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".list_14Lxp.list-selectableItemList_3eP0r {\n  display: grid;\n  overflow-y: auto;\n  padding-left: 4px;\n  grid-row-gap: var(--size-medium);\n  padding-right: 4px;\n  padding-bottom: var(--size-borderRadiusLarge);\n  grid-template-columns: 1fr;\n}\n", ""]);
// Exports
exports.locals = {
	"list": "list_14Lxp",
	"list-selectableItemList": "list-selectableItemList_3eP0r"
};
module.exports = exports;
