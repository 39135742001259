import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { FadingCircle } from 'better-react-spinkit'
import { Content, Flex, Button, Text } from '@leverege/ui-elements'
import { withAuth0 } from '@auth0/auth0-react'

import S from './AssocFailure.less'
import ScanFooter from '../scanFooter/ScanFooter'
import { UI, PinpointActions, Constants } from '../../actions'

class AssocFailure extends React.Component {

  static contextTypes = {
    store : PropTypes.object
  };

  static propTypes = {
    ui : PropTypes.object.isRequired,
    pinpoint : PropTypes.object.isRequired
  };

  componentDidMount() {
    const { ui : { mode } } = this.props
    const { dispatch } = this.props
    const text = mode === Constants.MODE_PAIR ? 'Association Failed' : 'Disassociation Failed'
    dispatch(
      UI.set(
        'scanHeader',
        {
          variant : 'error',
          text,
          cancel : true
        }
      )
    )
  }

  onRetry = () => {
    const { dispatch, auth0 } = this.props
    dispatch( PinpointActions.associate( auth0.logout ) )
  }

  onBack = () => {
    const { dispatch } = this.props
    dispatch( push( '/tracker' ) )
  }

  render() {
    const { ui, pinpoint } = this.props
    const { error, status } = pinpoint
    const { mode } = ui
    
    const loading = status === Constants.ASSOC_PENDING
    let errorMessage
    if ( loading ) {
      errorMessage = <FadingCircle />
    } else if ( error && error.message ) {
      if ( error.status === 500 ) {
        errorMessage = Constants.DEFAULT_ERROR
      } else {
        errorMessage = error.message
      }
    } else {
      errorMessage = `Unknown error. Please contact an admin with this error: ${JSON.stringify( error )}`
    }
    return (
      <Content>
        <Content.Area>
          <Flex direction="column" className={S.container}>
            <Text data-automation="trackerid-not-found-msg" className={S.resultText}>
              {errorMessage}
            </Text>
            <Button onClick={this.onRetry} className={classnames( S.actionButton, S.blue )}>
              Retry
            </Button>
          </Flex>
        </Content.Area>
        <Content.Footer className={S.footer}>
          <ScanFooter
            onBack={this.onBack}
            showBack
            showNext={false}
            progress={mode === Constants.MODE_PAIR ? { progress : 2, total : 3 } : { progress : 1, total : 2 }} />
        </Content.Footer>
      </Content>
    )
  }
}

export default connect( 
  state => ( {
    ui : state.ui,
    pinpoint : state.pinpoint.assoc
  } )
)( withAuth0( AssocFailure ) )
